export const tableItems = [
  { label: 'Старт работы казино', value: '2023 год' },
  { label: 'Лицензия', value: 'Curacao' },
  { label: 'Тип казино', value: 'Браузерная и мобильная версии' },
  { label: 'Язык официального сайта', value: 'Английский, Русский' },
  { label: 'Зеркало официального сайта', value: 'casino7-a.com' },
  {
    label: 'Провайдеры',
    value:
      'Igrosoft, Microgaming, NetEnt, Pragmatic PIay, PIayson, Quickspin, EvoIution Gaming, Novomatic, YggdrasiI Gaming, КТ, BeIatra, Amatic, Unicum, Вооопдо, Evoplay, Thunderkick, Spinomenal, Aristocrat, kiosk Games, Spinmatic, lnbet',
  },
  { label: 'Количество игр', value: '2 000+' },
  { label: 'Валюта счета', value: 'RUB, EUR, USD, UAH, KZT' },
  { label: 'Саппорт', value: 'Работает в режиме 24/7' },
];
