import { Container } from '@/shared/ui/container';
import styles from './styles.module.scss';
import { Button } from '@/shared/ui/button';
import { useLinks } from '@/shared/context/links.context';

export const Header = () => {
  const links = useLinks();

  return (
    <header className={styles.header}>
      <Container className={styles.container}>
        <a href="/" className={styles.logo}>
          <img className={styles.img} src="/logo.svg" alt="Casino7" />
        </a>
        <div className={styles.btns}>
          <Button href={links?.login} className={styles.btn} variant="blue">
            Вход
          </Button>
          <Button href={links?.register} className={styles.btn} variant="red">
            Регистрация
          </Button>
        </div>
      </Container>
    </header>
  );
};
